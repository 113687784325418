<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col xl="9" lg="7" md="12" sm="12" xs="12">
        <placeholder header="contact-title" text="contact-text"></placeholder>
      </v-col>
      <v-col>
        <v-card color="accent" class="pa-8" elevation="10">
          <p class="display-1 text-up font-weight-bold">
            {{ $t("contact-addresses") }}
          </p>
          <p>
            {{ $t("contact-general") }}<br />
            <a class="title" :href="'mailto:' + generalAddress">{{
              generalAddress
            }}</a>
          </p>
          <p>
            {{ $t("contact-help") }}<br />
            <a class="title" :href="'mailto:' + helpAddress">{{
              helpAddress
            }}</a>
          </p>
          <p>
            {{ $t("contact-tech") }}<br />
            <a class="title" :href="'mailto:' + techAddress">{{
              techAddress
            }}</a>
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Placeholder from "@/components/Placeholder.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "contact",
  components: {
    Placeholder,
    Breadcrumbs
  },
  data: () => ({
    generalAddress: "mail@bauchgefuehle-giessen.de",
    helpAddress: "hilfe@bauchgefuehle-giessen.de",
    techAddress: "technik@bauchgefuehle-giessen.de"
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("contact"),
          disabled: true
        }
      ];
    }
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
